<template>
  <PageWithLayout>
    <GroupBoxWidget>
      <BoxWidget
        iconName="email"
        iconBgColor="#6CDFC3"
        title="푸시도달"
        :isProgress="true"
        :grid="50"
        :value="5999999"
        :totalValue="9999999"
        :isShowTotal="true"
        :isShowPercent="true"/>
      <BoxWidget
        iconName="email"
        iconBgColor="#FFD82A"
        title="푸시오픈"
        :isProgress="true"
        :grid="50"
        :value="4820321"
        :totalValue="9999999"
        :isShowTotal="true"
        :isShowPercent="true"/>
    </GroupBoxWidget>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송현황</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송상태</th>
          <td>
            <span class="txt_tbl">
              <Badge
                badgeSize="small"
                :text="viewModel.model.status | convertIdToText('alarm_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('alarm_status')" />
            </span>
          </td>
          <th>디바이스 푸시 유무</th>
          <td>
            {{ viewModel.model.isPush ? 'Y' : 'N' }}
          </td>
        </tr>
        <tr>
          <th>발송일시</th>
          <td>
            {{ viewModel.model.sendDate }}
          </td>
          <th>발송인</th>
          <td>
            박하영
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송목적</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>발송목적</th>
          <td>
            {{ viewModel.model.isTest | convertIdToText('alarm_test_register') }}
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">타겟설정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>광고유무</th>
          <td>
            {{ viewModel.model.isAd | convertIdToText('alarm_ad_register') }}
          </td>
        </tr>
        <tr>
          <th>플랫폼</th>
          <td>
            {{ viewModel.model.platform | convertIdToText('alarm_platform') }}
          </td>
        </tr>
        <tr>
          <th>회원구분</th>
          <td>
            {{ viewModel.model.audience | convertIdToText('alarm_audience') }}
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">레몬트리 앱내 알림</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>알림 타이틀<span class="txt_info">(채널 구분)</span></th>
          <td>
            {{ viewModel.model.chanel | convertIdToText('alarm_chanel') }}
          </td>
        </tr>
        <tr>
          <th>알림 본문</th>
          <td>{{ viewModel.model.content }}</td>
        </tr>
        <tr>
          <th>랜딩 URL</th>
          <td>{{ viewModel.model.landingUrl }}</td>
        </tr>
        <tr>
          <th>랜딩 버튼명</th>
          <td>{{ viewModel.model.landingBtnText }}</td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">디바이스 푸시</strong>
        </div>
        <div class="area_right">
          <SwitchCheck
            :value.sync="viewModel.model.isPush"
            :isDisabled="true"
            :isSwitchLeft="false"
            text="푸시 발송" />
          <SwitchCheck
            :value.sync="viewModel.model.isSameAppAlarm"
            :isDisabled="true"
            :isSwitchLeft="false"
            text="앱 내 알림과 동일한 내용 적용" />
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>푸시 타이틀</th>
          <td>{{ viewModel.model.pushTitle }}</td>
        </tr>
        <tr>
          <th>푸시 본문</th>
          <td>{{ viewModel.model.pushContent }}</td>
        </tr>
        <tr>
          <th>푸시 랜딩</th>
          <td>
            <p class="desc_info">💡 사용자가 푸시를 터치하면 앱내 알림에서 지정한 경로로 이동합니다. 지정한 경로가 없는 경우 레몬트리 앱이 실행됩니다.</p>
          </td>
        </tr>
      </template>
    </TableView>
    <TableView>
      <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">발송일정</strong>
        </div>
      </template>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
      </template>
      <template v-slot:tbl_body>
        <tr>
          <th>예약 일시</th>
          <td>{{ viewModel.model.send_at | convertDateFormat('YYYY-MM-DD HH:mm:ss') }}</td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="취소"
          routeName="APP_ALARM_LIST"
          :routeQuery="{ status : viewModel.model.tabStatus }"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="primary_border"
          text="내용복제"/>
      </div>
    </Sticky>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import GroupBoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/GroupBoxWidget';
import BoxWidget from '@lemontree-ai/lemontree-admin-common-front/components/common/boxInfo/BoxWidget';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import IconSvg from '@lemontree-ai/lemontree-admin-common-front/components/common/icon/IconSvg';
import Radio from '@lemontree-ai/lemontree-admin-common-front/components/common/radio/Radio';
import Selectbox from '@lemontree-ai/lemontree-admin-common-front/components/common/selectbox/Selectbox';
import Input from '@lemontree-ai/lemontree-admin-common-front/components/common/input/Input';
import SwitchCheck from '@lemontree-ai/lemontree-admin-common-front/components/common/switch/SwitchCheck';
import DatePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/datePicker/DatePicker';
import TimePicker from '@lemontree-ai/lemontree-admin-common-front/components/common/timePicker/TimePicker';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
import { convertIdToText, getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'
// viewModel
import AlarmDetailViewModel from '@/views/app/alarm/viewModel/AlarmDetailViewModel'

export default {
  name:'AlarmDetail',
  components:{
    PageWithLayout,
    TableView,
    GroupBoxWidget,
    BoxWidget,
    Badge,
    IconSvg,
    Radio,
    Selectbox,
    Input,
    SwitchCheck,
    DatePicker,
    TimePicker,
    Sticky,
    Button,
  },
  beforeMount(){
    const id = this.$route.params.id;
    const status = this.$route.params.status;
    this.viewModel.init(id,status);
  },
  data(){
    return{
      viewModel: new AlarmDetailViewModel(),
    }
  },
}
</script>