import FaqModel from '@/views/app/alarm/model/AlarmModel';
import router from '@/router';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';

export default class AlarmDetailViewModel {
  constructor() {
    this.categoryDataList = [];
    this.model = {};
    this.isModify = false;
    this.isFaqCategoryPopup = false;
    this.status = ''
  }

  init(id,status){
    // this.getFaqCategory();
    this.model = new FaqModel();
    if(id){
      this.getFaqDetail(id);
      this.model.tabStatus = status;
    }
  }

  onClickCategoryPoup(){
    this.isFaqCategoryPopup = true;
  }
  onModifyOrder(orderData){
    console.log(orderData)
  }
  onClickAddCategory(){
    const newCategory = {
      id: this.categoryDataList.length,
      categoryName:'',
      editabled: true
    }
    this.categoryDataList.push(newCategory);
  }
  onClickDeleteCategoryItem(id){
    const requestDelete = () => {
      this.categoryDataList = this.categoryDataList.filter(item => item.id != id);
      store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 삭제되었습니다.`);
    }
    store.dispatch('commonAlert/fetchAlertStart', {
      alertMsg: `<strong>카테고리를 삭제할까요?</strong>해당 카테고리에 지정된 FAQ 게시물이 있는 경우,<br>기타 카테고리로 변경됩니다.`,
      closeBtnText: '취소',
      compeleteBtnText: '확인',
      confirmCallBack: requestDelete,
    });
  }
  onClickCloseFaqCagegoryPopup(){
    this.isFaqCategoryPopup = false;
  }
  onClickCompleteFaqCagegoryPopup(){
    store.dispatch('commonToast/fetchToastStart', `FAQ 카테고리가 저장되었습니다.`);
    this.isFaqCategoryPopup = false;
  }
  
  onClickComplete(){
    let toastMessage = '';
    let routeName = '';
    if(this.isModify){
      toastMessage = '수정';
      routeName = 'APP_TERMS_DETAIL';
    }else{
      toastMessage = '등록';
      routeName = 'APP_TERMS_LIST';
    }
    store.dispatch('commonToast/fetchToastStart', `FAQ ${toastMessage}이 완료되었습니다.`);
    router.push({ name: routeName });
  }

  getFaqCategory(){
    this.categoryDataList = [
      { id:1, categoryName:'레몬트리카드', editabled: true },
      { id:2, categoryName:'인증/보안', editabled: true },
      { id:0, categoryName:'기타', editabled: false },
    ]
  }

  getFaqDetail(id){
    const resultData = {
      id:1,
      status:'ING',
      isTest:false,
      isAd:false,
      platform:'IOS',
      audience:'ALL',
      chanel:1,
      content:'레몬트리 약관이 변경됩니다 자세한 내용은 공지사항을 참고해 주세요',
      landingUrl:'https://lemontree.ai/',
      landingBtnText:'자세히 보기',
      isPush: true,
      pushTitle:'🍋 레몬트리 가이드',
      pushContent:'레몬트리 약관이 변경됩니다 자세한 내용은 공지사항을 참고해 주세요',
      send_at:'2022-05-30 23:59:00'
    }
    this.model.setData(resultData);
  }
}