import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils'
import { convertIdToText, getSelectDataList } from '@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils'

export default class AlarmModel {
  constructor(){
    this.objRaw = {};
    this.id = null;
    this.tabStatus = '';
    this.isTest = false;
    this.isAd = false;
    this.platform = '';
    this.audience = '';
    this.chanel = '';
    this.content = '';
    this.landingUrl = '';
    this.landingBtnText = '';
    this.isPush = false;
    this.isSameAppAlarm = false;
    this.pushTitle = '';
    this.pushContent = '';
    this.send_at = '';
  }
  setData(obj){
    if( obj ) this.objRaw = obj;
    const { id, status, isTest, isAd, platform, audience, chanel, content, landingUrl, landingBtnText, isPush, pushTitle, pushContent, send_at } = obj;
    this.id = id;
    this.status = status;
    this.isTest = isTest;
    this.isAd = isAd;
    this.platform = platform;
    this.audience = audience;
    this.chanel = chanel;
    this.content = content;
    this.landingUrl = landingUrl;
    this.landingBtnText = landingBtnText;
    this.isPush = isPush;
    this.pushTitle = pushTitle;
    this.pushContent = pushContent;
    this.send_at = send_at;
    const chanelText = convertIdToText(chanel,'alarm_chanel')
    if(chanelText === pushTitle && content === pushContent){
      this.isSameAppAlarm = true
    }
  }
  getData(){
    let obj = {
      id : this.id,
      isTest : this.isTest,
      isAd : this.isAd,
      platform : this.platform,
      audience : this.audience,
      chanel : this.chanel,
      content : this.content,
      landingUrl : this.landingUrl,
      landingBtnText : this.landingBtnText,
      pushTitle : this.pushTitle,
      pushContent : this.pushContent,
      sendDate : this.sendDate,
    }
    return obj;
  }
}